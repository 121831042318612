import axios from 'axios';
import { firestore } from '../../firebase';

import { GET_MONITORED_SUPPLIERS } from './types';
import { showPopUp } from './popUp.actions';

const convertTimeStampToDate = (timestamp) => {
  const date = `${timestamp.toDate().getUTCDate()}/${
    timestamp.toDate().getUTCMonth() + 1
  }/${timestamp.toDate().getUTCFullYear()}`;
  return date;
};

export const setSupplierListener = async (client, dispatch) => {
  if (client.monitoredSuppliers) {
    const clientMonitoredSuppliers = Object.values(client.monitoredSuppliers);

    const monitoredSuppliers = clientMonitoredSuppliers;

    clientMonitoredSuppliers.forEach((item, index) => {
      firestore
        .collection('reports')
        .where('domain', '==', item.domain)
        .where('client', '==', null)
        .where('emailAt', '==', item.atEmail || item.domain)
        .orderBy('timestamp', 'desc')
        .limit(1)
        .onSnapshot((snapshot) => {
          snapshot.forEach((doc) => {
            const supplierReport = doc.data();

            monitoredSuppliers[index] = {
              ...monitoredSuppliers[index],
              ...supplierReport,
              date: convertTimeStampToDate(supplierReport.timestamp),
            };
          });

          dispatch({
            type: GET_MONITORED_SUPPLIERS,
            payload: [...monitoredSuppliers],
          });
        });
    });
  }
};

const addSupplier = async (clientId, supplier) => {
  if (!supplier) return false;
  const saved = await axios
    .post(`/clients/${clientId}/supplier/`, { ...supplier })
    .then((res) => true)
    .catch((error) => false);
  return saved;
};

export const editSupplierList = (supplier) => async (dispatch, getState) => {
  const { client, popUp } = getState();
  if (!supplier) return;
  if (popUp.popUpCode === 'editSupplier') {
    const saved = await addSupplier(client.id, supplier);
    // if it's saved succesfully close modal else close and show console error.
    if (saved) {
      dispatch(showPopUp(null));
    } else {
      dispatch(showPopUp(null)); // todo show extra popUp for fails
    }
  }
};

export default true;

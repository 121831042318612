import api from '../config';
import { CLIENTS_API } from '../paths';

const updateWelcomePopup = (data) => {
  return api.put(`${CLIENTS_API}/welcome-popup`, data);
};

const addSupplier = (data) => {
  return api.post(`${CLIENTS_API}/suppliers`, data);
};

export default { updateWelcomePopup, addSupplier };
